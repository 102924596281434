/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $( '.wds-country-container' ).on( 'mouseenter', function() {
          $( '.wds-country-dropdown' ).css( 'display', 'block' );
        });
        
        $( '.wds-country-container' ).on( 'mouseleave', function() {
          $( '.wds-country-dropdown' ).css( 'display', 'none' );
        });

        $( 'button.menu-toggle' ).on( 'click', function() {
          $( '.mobile-site-search' ).toggleClass( 'show' );
        });

        if( $( '#billing_postcode' ).length > 0 ) {
            // Check the postal code on page load
            Sage.common.checkPostalCode();
            $( '#billing_postcode' ).on( "input", function() {
                // Check the postal code on input
                Sage.common.checkPostalCode();
            } );
        }
      },
      /**
       * If postal code matches, display installation options field.
       */
      checkPostalCode : function() {
        var postalCodesArray = [
          "L0A","L0B","L0C","L0G","L0N",
          "L9A","L9B","L9C","L9G","L9H","L9J","L9K","L9N","L9R","L9S","L9V","L9W","L9Z",
          "L4A","L4G","L4M","L4N",
          "L7C","L7E","L7G","L7J","L7K","L7T","L7P",
          "P1L","P1P","P1H",
          "P0B","P0C",
          "L3P","L3R","L3S","L3V","L3X","L3Y","L3Z",
          "N3C","N3E","N3H","N3R","N3S","N3T",
          "N1C","N1G","N1H","N1K","N1L","N1P","N1R","N1S","N1T",
          "L1B","L1C","L1E",
          "K1B","K1C","K1E","K1G","K1H","K1J","K1K","K1L","K1R","K1T","K1V","K1W","K1X",
          "L8E","L8G","L8H","L8J","L8K","L8L","L8M","L8N","L8P","L8R.S","L8T","L8V","L8W",
          "K2B","K2C","K2G","K2H","K2J","K2K","K2M","K2R","K2S","K2T","K2W",
          "K4A","K4B","K4C","K4M","K4P",
          "K0A","K0G","K0M",
          "K7A","K7C","K7H",
          "N2A","N2B","N2C","N2E","N2G","N2H","N2M","N2N","N2P","N2R",
          "K9V",
          "L2E","L2G","L2H","L2J",
          "L6B","L6C","L6E","L6H","L6J","L6K","L6L","L6M",
          "N0C","N0H",
          "N4L","N4K","N4S","N4T","N4V",
          "P2A",
          "K9H","K9J","K9K","K9L",
          "L9L","L9P",
          "P6A","P6B","P6C",
          "L8B",
          "N2J","N2K","N2L","N2T","N2V",
          "L1G","L1H","L1J","L1K","L1L","L1M","L1N","L1P","L1R","L1S","L1T","L1V","L1W","L1X","L1Y","L1Z"];

        var searchCode = $( '#billing_postcode' ).val().replace(/[^A-Z0-9]/ig, "").toUpperCase().slice(0,3);

        if( $.inArray(searchCode, postalCodesArray) > -1 ) {
            // The first three characters of postal code were found, so display the installation option
            $( '#installation_options_field' ).css( 'display', 'block' );
        } else {
            // Set the field to no, in case a customer changes the postal code
            $( '#installation_option_no' ).prop( 'checked', true );
            $( '#installation_options_field' ).css( 'display', 'none' );
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
